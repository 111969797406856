/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function Information() {
  return (
    <MKBox component='section' py={3} mt={8}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <DefaultInfoCard
              color='warning'
              icon='grid_view'
              title='PlantMaster'
              description='PlantMaster is a comprehensive software solution designed to streamline and optimize the management of industrial plants. It provides a range of features and tools to effectively oversee plant operations, including works orders, bill of materials, HR cost tracking, and overweight management. With PlantMaster, industrial plant managers can enhance productivity, improve efficiency, and gain valuable insights into their plant processes.'
              direction='center'
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <DefaultInfoCard
              color='warning'
              icon='grid_view'
              title='PrecisionCapture'
              description='PrecisionCapture is an advanced add-on module specifically designed for PlantMaster. It empowers industrial plant operators to capture real-time data on the shop floor and in the field. With the ability to collect data on tasks, workflows, and HR-related information, PrecisionCapture ensures accurate and up-to-date insights into plant operations. The module enables seamless integration between data capture, analysis, and reporting, facilitating informed decision-making and continuous improvement within the plant environment.'
              direction='center'
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
