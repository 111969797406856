/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";

// Imags
const bgImage =
  "https://images.unsplash.com/photo-1467541473380-93479a5a3ffa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=2246&amp;q=80";

function Pricing() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("monthly");

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  return (
    <MKBox component='section' py={{ xs: 0, lg: 7 }}>
      <MKBox
        borderRadius='xl'
        shadow='lg'
        sx={{
          backgroundImage: ({
            palette: { gradients },
            functions: { linearGradient, rgba },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
        }}
      >
        <Container sx={{ pb: { xs: 12, lg: 22 }, pt: 12 }}>
          <Grid
            container
            item
            flexDirection='column'
            alignItems='center'
            xs={12}
            md={8}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKBadge
              badgeContent='courses'
              variant='gradient'
              container
              color='dark'
              sx={{ mb: 1 }}
            />
            <MKTypography variant='h3' color='white' mb={2}>
              See our courses
            </MKTypography>
            <MKTypography variant='body2' color='white'>
              tailored to fit your unique learning style and pace. Learn your
              way."
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <MKBox mt={-16}>
        <Container>
          <Grid container sx={{ mb: 6 }}>
            <Grid
              item
              xs={7}
              md={6}
              lg={4}
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <AppBar position='static'>
                <Tabs value={activeTab} onChange={handleTabType}>
                  <Tab
                    id='monthly'
                    label={
                      <MKBox py={0.5} px={2} color='inherit'>
                        Standard
                      </MKBox>
                    }
                  />
                  <Tab
                    id='annual'
                    label={
                      <MKBox py={0.5} px={2} color='inherit'>
                        Real time guide
                      </MKBox>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
          <MKBox position='relative' zIndex={10} px={{ xs: 1, xl: 0 }}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12} lg={3}>
                <DefaultPricingCard
                  badge={{ color: "light", label: "chain101" }}
                  price={{
                    currency: "$",
                    value: tabType === "annual" ? 1800 : 600,
                  }}
                  specifications={[
                    { label: "Understanding the Basics", includes: true },
                    { label: "Hands-On Learning", includes: true },
                    {
                      label: "Real world Blockchain Applications ",
                      includes: true,
                    },
                    { label: "Security and Privacy", includes: true },
                    { label: "Future Trends", includes: true },
                    { label: "Complete documentation", includes: true },
                  ]}
                  action={{
                    type: "internal",
                    route: "../../../pages/blogs/author",
                    color: "dark",
                    label: "Contact",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <DefaultPricingCard
                  color='dark'
                  badge={{ color: "info", label: "BitcoinPro" }}
                  price={{
                    currency: "$",
                    value: tabType === "annual" ? 1500 : 250,
                    type: false,
                  }}
                  specifications={[
                    { label: "Bitcoin Basics", includes: true },
                    { label: "Bitcoin Transactions", includes: true },
                    { label: "Bitcoin Wallets and Security", includes: true },
                    { label: "Investing in Bitcoin", includes: true },
                    {
                      label: "Legal and Regulatory Considerations",
                      includes: true,
                    },
                    { label: "Complete documentation", includes: true },
                  ]}
                  action={{
                    type: "internal",
                    route: "../../../pages/blogs/author",
                    color: "info",
                    label: "Contact",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <DefaultPricingCard
                  badge={{ color: "light", label: "NFT Academy" }}
                  price={{
                    currency: "$",
                    value: tabType === "annual" ? 2990 : 699,
                  }}
                  specifications={[
                    { label: "NFT Basics", includes: true },
                    { label: "Creating and Minting NFTs", includes: true },
                    { label: "NFT Marketplaces", includes: true },
                    { label: "Use Cases and Industries", includes: true },
                    {
                      label: "Legal and Ethical Considerations",
                      includes: true,
                    },
                    { label: "Complete documentation", includes: true },
                  ]}
                  action={{
                    type: "internal",
                    route: "../../../pages/blogs/author",
                    color: "dark",
                    label: "Contact",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <DefaultPricingCard
                  color='dark'
                  badge={{ color: "info", label: "Web3Wizard" }}
                  price={{
                    currency: "$",
                    value: tabType === "annual" ? 2500 : 750,
                    type: "",
                  }}
                  specifications={[
                    { label: "Web3 Foundations", includes: true },
                    {
                      label: "Blockchain",
                      includes: true,
                    },
                    {
                      label: "Decentralized Applications (DApps)",
                      includes: true,
                    },
                    { label: "Web3 Protocols and Tools", includes: true },
                    {
                      label: "Decentralized Finance (DeFi)",
                      includes: true,
                    },
                    { label: "Complete documentation", includes: true },
                  ]}
                  action={{
                    type: "internal",
                    route: "../../../pages/blogs/author",
                    color: "info",
                    label: "Contact",
                  }}
                />
              </Grid>
            </Grid>
          </MKBox>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Pricing;
