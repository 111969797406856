/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { animateScroll as scroll } from "react-scroll";
import { useState, useRef } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/sclogos/Black on White.png";

function Profile({ contactRef }) {
  const [extended, setExtended] = useState(false);

  const handleMoreAboutUsClick = () => {
    let scrollAmount = 875;
    scroll.scrollMore(scrollAmount);
    setExtended(!extended);
  };

  const handleScrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <MKBox component='section' py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent='center' mx='auto'>
          <MKBox mt={{ xs: -16, md: -20 }} textAlign='center'>
            <MKAvatar
              src={profilePicture}
              alt='sclearing'
              size='xxl'
              shadow='xl'
            />
          </MKBox>
          <Grid container justifyContent='center' py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                mb={1}
              >
                <MKTypography variant='h3'>SC Learning LLC</MKTypography>
                <MKButton
                  variant='outlined'
                  color='info'
                  size='small'
                  onClick={handleScrollToContact}
                >
                  Let´s Talk
                </MKButton>
              </MKBox>

              <MKTypography variant='body1' fontWeight='light' color='text'>
                Empowering Your Business Growth through Digital Transformation{" "}
                {extended && (
                  <>
                    <br></br>
                    <p>
                      <br></br>
                      At SC Learning, we understand the importance of continuous
                      learning and digital transformation for the success of
                      your business. Our mission is to empower organizations
                      like yours to thrive in the rapidly evolving digital
                      landscape.
                    </p>
                    <br></br>
                    <p>
                      With SC Learning as your trusted partner, we offer
                      comprehensive guidance and support to design and implement
                      a strategic roadmap for your company's growth. Our team of
                      experts is dedicated to collaborating with you, leveraging
                      our industry knowledge and expertise, to create customized
                      solutions that drive digital transformation and maximize
                      your business potential.
                    </p>
                    <br></br>
                    <p>
                      We believe in the power of education and knowledge
                      sharing. Through our tailored learning programs and
                      innovative approaches, we equip your employees with the
                      skills and tools necessary to adapt, innovate, and stay
                      ahead in today's competitive market. From upskilling and
                      reskilling to fostering a culture of continuous
                      improvement, SC Learning ensures that your workforce is
                      always prepared to embrace new opportunities and overcome
                      challenges.
                    </p>
                    <br></br>
                    <p>
                      By embracing cutting-edge technologies and leveraging
                      data-driven insights, we help optimize your business
                      processes, streamline operations, and enhance overall
                      efficiency. Our comprehensive suite of services
                      encompasses digital strategy development, training and
                      development programs, change management, and ongoing
                      support to ensure a smooth and successful digital
                      transformation journey.
                    </p>
                    <br></br>
                    <p>
                      At SC Learning, our ultimate goal is your success. We
                      measure our achievements by the positive impact we have on
                      your business growth, customer satisfaction, and employee
                      engagement. Join us on this transformative journey and
                      unlock the full potential of your organization. Experience
                      the power of SC Learning, where knowledge meets
                      transformation, and take your business to new heights.
                    </p>
                  </>
                )}
                <br />
                <MKTypography
                  component='a'
                  href='#'
                  variant='body1'
                  fontWeight='light'
                  color='info'
                  mt={3}
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      transform: `translateX(3px)`,
                      transition:
                        "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round":
                      {
                        transform: `translateX(6px)`,
                      },
                  }}
                  onClick={handleMoreAboutUsClick}
                >
                  More about us{" "}
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
