/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import CoworkingPage from "pages/Apps/DDM";
import Presentation from "layouts/pages/presentation";

import Pricing from "layouts/pages/company/pricing";

import DesktopApp from "layouts/pages/apps/INDUSTRIAL";

import Author from "layouts/pages/blogs/author";

//import Presentation from "pages/presentation";

const routes = [
  {
    name: "our applications",
    icon: <Icon>dashboard</Icon>,
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Artificial Intelligent",
        collapse: [
          {
            name: "VisualVanguard",
            route: "/pages/presentation",
            component: <Presentation />,
          },
          {
            name: "AmplifyAI",
            route: "/pages/presentation",
            component: <Presentation />,
          },
        ],
      },
      {
        name: "Digital Document Manager",

        collapse: [
          {
            name: "DocuOrganizer",
            route: "/pages/apps/DDM",
            component: <CoworkingPage />,
          },
          {
            name: "TalentVault",
            route: "/pages/apps/DDM",
            component: <CoworkingPage />,
          },
        ],
      },
      {
        name: "Drones",

        collapse: [
          {
            name: "PalletPatrol",
            disabled: true,
            route: "/pages/presentation",
            component: <Presentation />,
          },
          {
            name: "AgroDronePro",

            route: "/pages/presentation",
            component: <Presentation />,
          },
          {
            name: "SkySentinel",
            disabled: true,
            route: "/pages/presentation",
            component: <Presentation />,
          },
          {
            name: "livestokEye",
            disabled: true,
            route: "/pages/presentation",
            component: <Presentation />,
          },
        ],
      },

      {
        name: "logistics & Warehouse",
        collapse: [
          {
            name: "PalletPal",
            route: "/pages/presentation",
            component: <Presentation />,
          },
          {
            name: "PalletPalInsights",
            route: "/pages/presentation",
            component: <Presentation />,
          },
          {
            name: "StorageSentry",
            route: "/pages/presentation",
            component: <Presentation />,
          },
          {
            name: "RouteGenius",
            route: "/pages/presentation",
            component: <Presentation />,
          },
        ],
      },
      {
        name: "Industrial Plants",
        collapse: [
          {
            name: "PlantMaster",
            route: "/pages/apps/INDUSTRIAL",
            component: <DesktopApp />,
          },
          {
            name: "PrecisionCapture",
            route: "/pages/apps/INDUSTRIAL",
            component: <DesktopApp />,
          },
        ],
      },
      {
        name: "Education",
        collapse: [
          {
            name: "chain101",
            route: "/pages/company/pricing",
            component: <Pricing />,
          },
          {
            name: "BitcoinPro",
            route: "/pages/company/pricing",
            component: <Pricing />,
          },
          {
            name: "Web3Wizard",
            route: "/pages/company/pricing",
            component: <Pricing />,
          },
          {
            name: "NFT Academy",
            route: "/pages/company/pricing",
            component: <Pricing />,
          },
          {
            name: "",
            route: "/pages/blogs/author",
            component: <Author />,
          },
        ],
      },
    ],
  },
];

export default routes;
