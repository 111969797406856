/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/bg-ddm.jpg";
import bgBack from "assets/images/worker.png";

function Information() {
  return (
    <MKBox component='section' py={6} my={6}>
      <Container>
        <Grid
          container
          item
          xs={11}
          spacing={3}
          alignItems='center'
          sx={{ mx: "auto" }}
        >
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon='touch_app'
                title={
                  <>
                    Pre-Built Efficiency
                    <br />
                    Solutions
                  </>
                }
                description='Crafted by industry experts, they streamline your operations, save time, reduce costs, and elevate productivity.'
              />
              <RotatingCardBack
                image={bgBack}
                title='Tailored Custom Solutions'
                description='"Unique business? Unique solutions. Our tailored services are built to match your distinct needs.'
                action={{
                  type: "internal",
                  route: "../../pages/blogs/author",
                  label: "let's go",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon='people'
                  title='Team'
                  description='Our team: skilled, multidisciplinary passionate professionals committed to meeting your unique challenges'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon='flip_to_front'
                  title='Innovation'
                  description='We shape the future with innovative, cutting-edge solutions, keeping you a step ahead.'
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon='price_change'
                  title='Process Optimization'
                  description='Transform your operations with our process optimization. We streamline your workflows, boosting efficiency and driving your business forward.'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon='devices'
                  title='Scalability'
                  description='We grow with you. Our scalable solutions support your business at every stage.'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
