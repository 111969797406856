// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/sclogos/Black on Transparent.png";
import { Instagram, LinkedIn } from "@mui/icons-material";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Searching Challenges",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <LinkedIn />,
      link: "/",
    },
    {
      icon: <TwitterIcon />,
      link: "/",
    },
    {
      icon: <Instagram />,
      link: "/",
    },
  ],
  menus: [
    {
      name: "company",
      items: [{ name: "about us", href: "/pages/blogs/author" }],
    },

    {
      name: "help & support",
      items: [{ name: "contact us", href: "/pages/blogs/author" }],
    },
    {
      name: "legal",
      items: [
        {
          name: "licenses (EULA)",
          href: "https://www.creative-tim.com/license",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant='button' fontWeight='regular'>
      All rights reserved. Copyright &copy; {date}{" "}
      <MKTypography
        component='a'
        href='https://www.creative-tim.com'
        target='_blank'
        rel='noreferrer'
        variant='button'
        fontWeight='regular'
      >
        ScLearning LLC
      </MKTypography>
      .
    </MKTypography>
  ),
};
