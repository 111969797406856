/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useRef, useState } from "react";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Profile from "pages/Blogs/Author/sections/Profile";

import Contact from "pages/Blogs/Author/sections/Contact";
import DefaultFooter from "examples/Footers/DefaultFooter";
// Routes
import routes from "routes";

import footerRoutes from "footer.routes";
// Images
import bgImage from "assets/images/city-profile.jpg";

function Author() {
  const contactRef = useRef(null);
  const [sendInfo, setSendInfo] = useState(true);
  const [success, setSuccess] = useState(true);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/pages/blogs/author",
          label: "Contact",
          color: "info",
        }}
        transparent
        light
      />

      <MKBox bgColor='white'>
        <MKBox
          minHeight='50rem'
          width='100%'
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Profile contactRef={contactRef} />
        </Card>
        <div ref={contactRef}>
          {sendInfo && (
            <Contact
              sendInfo={sendInfo}
              setSuccess={setSuccess}
              setSendInfo={setSendInfo}
            />
          )}
        </div>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

export default Author;
