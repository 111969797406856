/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

function Information() {
  return (
    <MKBox component='section' py={{ xs: 6, md: 12 }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <SimpleInfoCard
              icon='api'
              title='DocuOrganizer'
              description='DocuOrganizer is a cutting-edge software solution designed to revolutionize the way you handle your digital documents. With its advanced features and intuitive interface, it provides a seamless and organized experience for managing your important files.'
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <SimpleInfoCard
              icon='personPin'
              title='TalentVault'
              description='TalentVault is a powerful software solution designed specifically for HR professionals like you, providing a centralized and secure platform to streamline your HR document management processes. With TalentVault, you can finally regain control over your HR documents and focus on what truly matters - your people.'
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
